.comparison-view-container {
  display: flex;
  flex-direction: column;
  margin-top: -30px;

  height: calc(100vh - 60px);
  width: 100vw;
  box-sizing: border-box;
}

.comparison-view-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.comparison-view-summary-title-container {
  display: flex;
  flex-direction: row;

  width: 100%;
  box-sizing: border-box;
}

.comparison-view-summary-title-section {
  width: 50%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.comparison-view-summary-title-section {
  border-right: 4px dashed black;
}

.comparison-view-summary-title-section ~ .comparison-view-summary-title-section {
  border: none;
}

.comparison-view-summary-title {
  font-size: 2.25rem;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}

.comparison-view-summary-subtitle {
  margin-top: 0.5rem;

  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

.comparison-view-transfers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 90%;
  padding: 0.5rem;
  margin: 1.25rem 0;
  border: 2px solid black;
  border-radius: 16px;
}

.comparison-view-transfer-container {
  padding: 0.25rem;
}

.comparison-view-transfer-title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}

.comparison-view-transfer-description {
  font-size: 1rem;
  text-align: center;
}

.comparison-view-summary {
  font-size: 1.25rem;
  text-align: center;
  font-weight: bold;
}

.ratio {
  font-size: 1.1rem;
  font-weight: bold;
}

.green {
  color: green;
}

.red {
  color: red;
}

.comparison-view-policies-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;

  margin-top: 0rem;
  overflow: hidden;  
}

.comparison-view-policy-container {
  display: flex;
  flex-direction: column;

  width: 50%;
  padding: 0.25rem 1rem;
  box-sizing: border-box;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.comparison-view-policy-container {
  border-right: 4px dashed black;
}

.comparison-view-policy-container ~ .comparison-view-policy-container {
  border: none;
}

.comparison-view-recalculate-button-container {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
}

.policy-recalculate-button {
  height: 40px;
  border-radius: 8px;
  padding: 0 12px;
  margin-left: 1rem;

  background-color: black;
  color: #ebebeb;

  font-family: 'Recursive', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}

.policy-recalculate-button:hover {
  background-color: #ebebeb;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.modal-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 60%;
  min-height: 60%;
  max-height: 75%;
  background-color:white;

  padding: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal .policy-recalculate-button {
  margin: 0;
}

.modal .assumptions-container {
  margin: 2rem 0;
  width: 100%;
  flex: 1;

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.modal .assumptions-container .assumptions-section {
  margin: 1rem 0;
}

.modal .assumptions-container .assumptions-section .assumptions-title {
  text-align: center;
  font-size: 1.5rem;
  text-decoration: underline;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
