.policy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: 0 1.5rem 1rem;
  box-sizing: border-box;

  font-family: 'Recursive', sans-serif;
}

.policy-container .policy-title {
  padding: 1rem;

  font-size: 3.6rem;
  font-weight: bold;
  text-align: center;
}

.policy-container .policy-description {
  padding: 0.5rem 0;
  font-size: 1.25rem;
}

.policy-container .policy-heading {
  margin-top: 1.75rem;
  margin-bottom: 1rem;

  font-weight: 600;
  font-size: 2rem;
  text-decoration: underline;
  text-align: center;
}

.policy-container .policy-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 1.25rem 0;
}

.policy-container .policy-recalculate-button {
  width: 75%;
  height: 48px;
  border-radius: 8px;

  background-color: black;
  color: #ebebeb;

  font-family: 'Recursive', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}

.policy-container .policy-recalculate-button:hover {
  background-color: #ebebeb;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}