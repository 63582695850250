.policy-info-section-container {
  margin-bottom: 1rem;
}

.policy-info-section-container .policy-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.policy-info-section-container .policy-heading:hover {
  cursor: pointer;
}

.policy-info-section-container .policy-heading .policy-heading-title {
  margin-bottom: 1rem;

  font-weight: 600;
  font-size: 1.75rem;
  text-decoration: underline;
  text-align: left;
}