.full {
  width: 100%;
}

.collapsable-title {
  margin-bottom: 1rem;

  font-weight: 600;
  font-size: 1.25rem;
  text-decoration: underline;
  text-align: left;
}

.programs-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}

.programs-list .program-item {
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;

  text-align: center;
  font-size: 1.25rem;
}

.programs-list .program-item:hover {
  background-color: yellow;

  font-weight: bold;
  text-decoration: underline;

  border-radius: 16px;
  cursor: pointer;
}