.app {
  height: 100%;
  width: 100%;
  font-family: 'Recursive', sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app .header {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  height: 64px;
  padding: 8px 16px;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.app .header .logo {
  height: 40px;
}

.app .header .logo:hover {
  cursor: pointer;
}

.app .password-container {
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app .password-container .enter-password-prompt {
  font-size: 3rem;
  font-weight: bold;
}

.app .password-container .password-input {
  font-size: 1.8rem;

  width: 100%;
  padding-left: 0.5rem;
  box-sizing: border-box;
}

.app .password-container .enter-password-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 1.25rem 0;
}

.app .password-container .enter-password-button {
  width: 75%;
  height: 48px;
  border-radius: 8px;

  background-color: black;
  color: #ebebeb;

  font-family: 'Recursive', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}

.app .password-container .enter-password-button:hover {
  background-color: #ebebeb;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.app .hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.app .hero-container .hero-title {
  padding: 0.25rem 1rem 0;

  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
}

.app .comparison-section-container {
  width: 75%;
  margin-top: 1rem;
  padding: 1.5rem;

  border: 4px solid black;
  border-radius: 20px;
}

.app .comparison-section-container .comparison-section-title {
  text-align: center;
  text-decoration: underline;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app .comparison-section-container .comparison-section-policy-option {
  margin: 1rem;
}

.app .comparison-section-container .comparison-section-policy-header {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.app .comparison-section-container .comparison-section-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 1.25rem;
}

.app .comparison-section-container .comparison-section-button {
  width: 75%;
  height: 48px;
  border-radius: 8px;

  background-color: black;
  color: #ebebeb;

  font-family: 'Recursive', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}

.app .comparison-section-container .comparison-section-button:disabled {
  cursor: not-allowed;
}

.app .comparison-section-container .comparison-section-button:hover {
  background-color: #ebebeb;
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.app .section-container {
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.app .section-container .section-title {
  padding: 1rem 1rem;

  font-weight: bold;
  text-align: center;
  text-decoration: underline;
  font-size: 1.5rem;
}

.app .section-container .section-description {
  padding: 0.5rem 1rem;

  font-size: 1.2rem;
}

.link-text {
  display: inline;
  padding: 0.25rem;
}

.app .section-container .programs-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  align-items: center;
}

.app .programs-list .program-item {
  width: 50%;
  padding: 1rem;
  box-sizing: border-box;

  text-align: center;
  font-size: 1.25rem;
}

.app .programs-list .program-item:hover {
  background-color: yellow;

  font-weight: bold;
  text-decoration: underline;

  border-radius: 16px;
  cursor: pointer;
}