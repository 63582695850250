.dd-wrapper {
  position: relative;
  padding: 0.5rem;
  min-width: 300px;

  border: 1px solid black;
  border-radius: 4px;
}

.dd-wrapper:hover {
  cursor: pointer;
}

.dd-searchbar {
  padding: 0.5rem;
  
  margin-top: 4px;
}

.dd-searchbar .search-input {
  font-size: 1.25rem;

  width: 100%;
  padding-left: 0.5rem;
  box-sizing: border-box;
  height: 40px;

  border: 2px solid black;
  border-radius: 4px;
}

.dd-list-container {
  position: absolute;
  z-index: 10;
  max-height: 260px;

  left: 0;
  right: 0;

  margin-top: 0.5rem;
  margin-left: -1px;

  background-color: white;

  border: 1px solid black;
}

.dd-list {
  position: absolute;
  z-index: 8;
  max-height: 215px;
  overflow-y: scroll;
  padding: 0.5rem;

  left: 0;
  right: 0;

  margin-left: -1px;
  margin-right: -1px;

  background-color: white;

  font-weight: 700;
  text-align: left;

  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.dd-list-item {
  padding: 0.5rem 0.25rem;
}

.dd-list-item:hover {
  background-color: yellow;
  text-decoration: underline;
  cursor: pointer;
}
