.policy-info-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  margin: 0.5rem 0;
  padding: 0 1rem;
  box-sizing: border-box;
}

.policy-info-div .policy-info-title {
  font-weight: bold;
  font-size: 1.3rem;
}

.policy-info-div .policy-info-value {
  margin-top: 4px;
  margin-left: 8px;
  font-size: 1.2rem;
}