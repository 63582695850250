.input-div {
  display: flex;
  flex-direction: row;

  padding: 0.25rem 1rem;
}

.input-div .input-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 50%;
  box-sizing: border-box;
}

.input-div .input-info .input-title {
  font-size: 1.3rem;
  font-weight: 600;

  margin: 0.25rem 0 0.1rem;

  text-align: center;
}

.input-div .input-info .input-description {
  font-size: 1rem;

  margin: 0.25rem 0 0.5rem;

  text-align: center;
}

.input-div .input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 50%;
  margin: 0 1rem;
  box-sizing: border-box;
}

.input-div .input-container .input-qualifier {
  font-size: 1.75rem;
}

.input-div .input-container .input {
  font-size: 1.8rem;

  width: 100%;
  padding-left: 0.5rem;
  box-sizing: border-box;

  border-top: none;
  border-left: none;
  border-right: none;
}