.policy-info-section-container .policy-output-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.policy-info-section-container .policy-output-div .policy-output-title {
  font-weight: bold;
  font-size: 1.3rem;
}

.policy-info-section-container .policy-output-div .policy-output-value {
  margin-left: 8px;
  font-size: 1.2rem;
}

.policy-info-section-container .policy-output-div .mvpf-title {
  margin-bottom: 4px;
  font-size: 1.5rem;
}

.policy-info-section-container .policy-output-div .mvpf-value {
  font-size: 1.25rem;
}

.infinity {
  color: green;
  font-size: 2rem;
  font-weight: bold;
}